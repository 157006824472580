import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  FormCheck,
  FormControl,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Row,
} from 'react-bootstrap';
import { API } from 'aws-amplify';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { FormattedMessage, Link as LinkIntl, useIntl } from 'gatsby-plugin-intl';

import ModalHeader from 'react-bootstrap/ModalHeader';
import configureAmplify from '../../config/amplify';

import * as classes from './ContactForm.module.css';

import BackgroundTriangle from '../BackgroundTriangle/BackgroundTriangle';

configureAmplify();

const ContactForm = (props) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [badRequestError, setBadRequestError] = useState(false);
  const [show, setShow] = useState(false);

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [agree, setAgree] = useState(false);

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(content/pages/contact)/" } }) {
        nodes {
          frontmatter {
            contact {
              image {
                publicURL
                extension
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          fields {
            language
          }
        }
      }
    }
  `);
  const contactImage = data.allMarkdownRemark.nodes.find((element) => element.fields.language === intl.locale)
    ?.frontmatter?.contact?.image?.childImageSharp?.fluid;

  const resetHandler = () => {
    setName('');
    setCompany('');
    setEmail('');
    setReason('');
    setMessage('');
    setAgree(false);
  };

  const createGAEvent = (category, action, label = null) => {
    trackCustomEvent({
      category,
      action,
      label,
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      setError(false);
      setBadRequestError(false);
      setLoading(true);

      const dto = {
        name,
        company,
        email,
        reason,
        message,
      };

      await API.post('default', '/contact', { body: dto });

      resetHandler();
      createGAEvent('Contact Form', 'Submit');
    } catch (err) {
      console.error(err);
      setError(true);

      if (err.response && err.response.status === 400) {
        setBadRequestError(true);
      }
    } finally {
      setShow(true);
      setLoading(false);
    }
  };

  const focusHandler = (event) => {
    event.preventDefault();
    if (!event.target || !event.target.id) {
      return;
    }
    createGAEvent('Contact Form', 'Focus', event.target.id);
  };

  const handleClose = () => setShow(false);

  const formIsValid = () => {
    return !!name && !!email && !!reason && !!message && agree;
  };

  const renderModal = () => (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader closeButton={true}>
        <ModalTitle>
          <FormattedMessage id="pages.home.sections.contact.title" />
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        {error ? (
          <span>
            { badRequestError ? (
              intl.formatMessage({ id: 'errors.validation' })
            ) : (
              <>
                {intl.formatMessage({ id: 'errors.general' })}{' '}
                &#107;&#111;&#110;&#116;&#97;&#107;&#116;&#64;&#98;&#101;&#98;&#101;&#110;&#115;&#101;&#101;&#46;&#105;&#116;
              </>
            )}

          </span>
        ) : (
          'Vielen Dank für Ihre Anfrage. Unsere Mitarbeiter werden sich schnellstmöglich bei Ihnen melden.'
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" type="button" onClick={handleClose}>
          Schießen
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <section id="contact" className="section-content" style={{ position: 'relative' }}>
      <BackgroundTriangle left={true} />
      {renderModal()}
      <Container fluid>
        <h2 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.contact.headline }} />
        {props.content?.contact?.text && (
          <p className="section-headline-small" dangerouslySetInnerHTML={{ __html: props.content.contact.text }} />
        )}

        <div className={classes.ContactFormRow}>
          <Row style={{ marginTop: props.content?.contact?.text ? '3rem' : null }}>
            <Col xs={12} lg={6}>
              <div className={classes.ContactImageWrapper}>
                <div className={classes.ContactFormImage}>
                  <div className={classes.ContactDecoration1} />
                  <div className={classes.ContactDecoration2} />
                  <Img fluid={contactImage} alt="Bebensee IT GmbH" className={classes.ContactFormImageFluid} />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className={classes.ContactFormColRight}>
                <form onSubmit={submitHandler}>
                  <FormGroup>
                    <label htmlFor="name">
                      <FormattedMessage id="pages.home.sections.contact.name" />*
                    </label>
                    <FormControl
                      id="name"
                      type="text"
                      required={true}
                      maxLength="250"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onFocus={focusHandler}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="company">
                      <FormattedMessage id="pages.home.sections.contact.company" />
                    </label>
                    <FormControl
                      id="company"
                      type="text"
                      maxLength="250"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      onFocus={focusHandler}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="email">
                      <FormattedMessage id="pages.home.sections.contact.mail" />*
                    </label>
                    <FormControl
                      id="email"
                      type="email"
                      required={true}
                      maxLength="250"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={focusHandler}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="reason">
                      <FormattedMessage id="pages.home.sections.contact.reason" />*
                    </label>
                    <FormControl
                      as="select"
                      id="reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      required={true}
                      onFocus={focusHandler}
                    >
                      <option value="">
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.choose' })}
                      </option>
                      <option value="project-request">
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.project-request' })}
                      </option>
                      <option value="service-question">
                        {' '}
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.service-question' })}
                      </option>
                      <option value="blog-feedback">
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.blog-feedback' })}
                      </option>
                      <option value="opened-project">
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.opened-project' })}
                      </option>
                      <option value="applying-job">
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.applying-job' })}
                      </option>
                      <option value="legal-request">
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.legal-request' })}
                      </option>
                      <option value="sales-request">
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.sales-request' })}
                      </option>
                      <option value="other">
                        {intl.formatMessage({ id: 'pages.home.sections.contact.reasons.other' })}
                      </option>
                    </FormControl>
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="message">
                      <FormattedMessage id="pages.home.sections.contact.message" />*
                    </label>
                    <FormControl
                      as="textarea"
                      id="message"
                      name="message"
                      rows="5"
                      required={true}
                      maxLength="4000"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onFocus={focusHandler}
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormCheck>
                      <FormCheck.Input
                        type="checkbox"
                        id="agree"
                        required={true}
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                        onFocus={focusHandler}
                      />

                      <FormCheck.Label htmlFor="agree" style={{ paddingLeft: '0.5em' }}>
                        <LinkIntl to="/data-privacy/index.html">
                          <FormattedMessage id="pages.home.sections.contact.privacy_statement" />
                        </LinkIntl>{' '}
                        <FormattedMessage id="pages.home.sections.contact.red" />
                      </FormCheck.Label>
                    </FormCheck>
                  </FormGroup>

                  <FormGroup style={{ marginBottom: 0 }}>
                    <Button
                      variant="light"
                      size="lg"
                      className={classes.ContactFormButton}
                      style={{
                        color: 'white',
                        backgroundColor: '#009688',
                        minWidth: '230px',
                        borderRadius: '8px',
                      }}
                      type="submit"
                      disabled={!formIsValid() || loading}
                    >
                      <FormattedMessage id="buttons.send" />
                    </Button>
                  </FormGroup>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ContactForm;
